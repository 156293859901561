import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../components/layout/layout';
import BookCard from '../../components/book-card';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = (props, location) => <Layout title="Seasons of Thought" description="A collection of poems by D.S. Chapman that traces its themes across the changing seasons." type="Book 📖" location={location} {...props} />;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <BookCard mdxType="BookCard" />{' '}
    <h2 {...{
      "id": "containing-the-poems"
    }}>{`Containing the poems`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought/advent"
        }}>{`Advent`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought/jazz-quartet-on-christmas-eve"
        }}>{`Jazz Quartet on Christmas Eve`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought/the-fox"
        }}>{`The Fox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought/the-top"
        }}>{`The Top`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought/evening-praise"
        }}>{`Evening Praise`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought/evergreen"
        }}>{`Evergreen`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought/another"
        }}>{`Another`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought/sunset"
        }}>{`Sunset`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      